import { ExternalRoute } from "@/constants/externalRoute.ts"
import { NAV_LABELS } from "@/constants/navigation/navigationTypes.ts"
import { Route as InternalRoute } from "@/constants/route.ts"
import { NavbarItem } from "@/types/Navbar.ts"

const TOP: NavbarItem[] = [
  { to: InternalRoute.profile, label: NAV_LABELS.profile },
  { to: InternalRoute.help, label: NAV_LABELS.helpAndAdvice },
] as const

const SIDEBAR: NavbarItem[] = [
  { to: InternalRoute.home, label: NAV_LABELS.accountHome },
  { to: ExternalRoute.meterReadings, label: NAV_LABELS.meterReadings },
  {
    to: ExternalRoute.billsAndStatements,
    label: NAV_LABELS.billsAndStatements,
  },
  {
    to: import.meta.env.VITE_USE_NEXUS_TARIFF_PAGE
      ? InternalRoute.tariff
      : ExternalRoute.tariff,
    label: NAV_LABELS.tariff,
  },
  { to: ExternalRoute.referAFriend, label: NAV_LABELS.referAFriend },
] as const

const TABLET: NavbarItem[] = [
  {
    label: NAV_LABELS.menu,
    children: [{ label: NAV_LABELS.account, children: [...SIDEBAR] }, ...TOP],
  },
] as const

export const SMART_PAY_AS_YOU_GO_NAVIGATION = {
  TOP,
  SIDEBAR,
  TABLET,
  MOBILE: [...SIDEBAR, ...TOP] as NavbarItem[],
} as const
