export const NAV_LABELS = {
  menu: "Menu",
  account: "Account",
  accountHome: "Account Home",
  home: "Home",
  energyUsage: "Energy Usage",
  usage: "Usage",
  billsAndStatements: "Bills & Statements",
  payments: "Payments",
  tariff: "Tariff",
  tariffDetails: "Tariff details",
  personalDetails: "Personal Details",
  referAFriend: "Refer a Friend",
  readings: "Readings",
  meterReadings: "Meter Readings",
  movingHome: "Moving Home",
  aboutUs: "About Us",
  aboutSoEnergy: "About So Energy",
  reviews: "Customer Reviews",
  news: "News & Views",
  careers: "Work for So",
  ourEnergy: "Our Energy",
  smartMeterBooking: "Smart Meter Booking",
  warmHomeDiscount: "Warm Home Discount",
  getSupport: "Get Support",
  energySaving: "Energy Saving Tips",
  unhappy: "Unhappy with Us?",
  emergency: "Emergency",
  priceCap: "Price Cap",
  troubleshooting: "IHD Troubleshooting",
  help: "Help",
  products: "Products",
  solar: "Solar",
  homeCover: "Home Cover",
  heatPumps: "Heat Pumps",
  smartMeters: "Smart Meters",
  renewal: "Renewal",
  profile: "Profile",
  helpAndAdvice: "Help & Advice",
} as const

export type NavLabel = (typeof NAV_LABELS)[keyof typeof NAV_LABELS]
